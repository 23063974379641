import { useState } from "react";
import { MAX_ESTIMATE_LENGTH, MAX_NAME_LENGTH } from "../App";

const estimatePresetButtons = [0, 1, 2, 3, 5, 8, 13, 20, 40, 100, '?', '☕']

function PlayerControls(props) {
    const player = props.player
    const setPlayer = props.setPlayer
    const [playerStatus, setPlayerStatus] = useState(player.status === 'participant');

    const savePlayerName = (event) => {
        setPlayer({
            ...player,
            name: String(event.target.value).slice(0, MAX_NAME_LENGTH)
        })
    }

    const togglePlayerStatus = (event) => {
        const isChecked = event.target.checked
        setPlayerStatus(isChecked)
        const newStatus = (isChecked) ? 'participant' : 'observer'
        if (newStatus === "observer") {
            setPlayer({
                ...player,
                estimate: ''
            })
        }
        setPlayer({
            ...player,
            status: newStatus
        })
    }

    const savePlayerEstimate = (value) => {
        setPlayer({
            ...player,
            estimate: String(value).slice(0, MAX_ESTIMATE_LENGTH)
        })
    }

    const statusLabel = (player.status === 'participant')
        ? <span>Participant</span>
        : <span className='has-text-grey'>Observer</span>

    return (
        <div className="box has-background-primary has-text-primary-invert py-4">
            <div className="columns is-multiline">
                <div className="column is-half-desktop is-full-tablet">
                    <div className="field">
                        <label className="label">Your name</label>
                        <div className="control has-icons-left">
                            <input maxLength={MAX_NAME_LENGTH} className="input" type="text" placeholder="Your name" value={player.name} onChange={(e) => savePlayerName(e)} />
                            <span className="icon is-small is-left">
                                <i className="fas fa-user"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="column is-half-desktop is-full-tablet">
                    <div className="field">
                        <label className="label">Your status</label>
                        <label className="fake-control" htmlFor="menuitem-visible">
                            <div className="checkbox-toggle">
                                <input type="checkbox" checked={playerStatus} onChange={(e) => togglePlayerStatus(e)} id="menuitem-visible" />
                                <span className="slider round"></span>
                            </div>
                            {statusLabel}
                        </label>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Your estimation</label>
                <div className="control">
                    <input className="input" maxLength={MAX_ESTIMATE_LENGTH} type="text" placeholder="Your estimation" disabled={player.status === 'observer'} value={player.estimate} onChange={e => savePlayerEstimate(e.target.value)} />
                </div>
            </div>
            <div className="buttons are-small">
                <button onClick={() => savePlayerEstimate('')} className="button is-rounded" disabled={player.status === 'observer'}>✖</button>
                {estimatePresetButtons.map((val, i) => {
                    return <button key={i} onClick={() => savePlayerEstimate(val)} className="button is-rounded" disabled={player.status === 'observer'}>{val}</button>
                })}
            </div>
        </div>
    );
}

export default PlayerControls;
import React from 'react';

export function ConnectionState({ isConnected }) {

    let connectionTag
    if (isConnected) {
        connectionTag = <span className='has-text-grey-dark'>Connected</span>
    } else {
        connectionTag = <span className='has-text-danger'>Disconnected</span>
    }

    return (
        <span>
            Server state: {connectionTag}
        </span>
    )
}
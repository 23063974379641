export const EVENT_STARTUP = 'startup'
export const EVENT_CLEAR_ALL_ESTIMATES = 'clearAllEstimates'
export const EVENT_NEW_GAME_STATE = 'newServerState'
export const EVENT_PLAYER_PROPERTIES_SET = 'playerPropertiesSet'
export const EVENT_NEW_PLAYER = 'newPlayer'
export const EVENT_PLAYER_LEFT = 'playerLeft'

export const COMMAND_SET_PLAYER_PROPERTIES = 'setPlayerProperties'
export const COMMAND_SET_GAME_STATE = 'setServerState'
export const COMMAND_CLEAR_ALL_ESTIMATES = 'clearAllEstimates'
export const COMMAND_NEXT_ROUND = 'nextRound'

import { COMMAND_NEXT_ROUND, COMMAND_CLEAR_ALL_ESTIMATES, COMMAND_SET_GAME_STATE } from "../app/io-constants"
import { socket } from "../socket"

function PhaseControls(props) {
    const gameState = props.gameState
    const player = props.player
    const players = props.participants

    const setReveal = (state) => {
        socket.emit(COMMAND_SET_GAME_STATE, { reveal: state })
    }

    const clearAllEst = () => {
        socket.emit(COMMAND_CLEAR_ALL_ESTIMATES)
    }

    const nextRound = () => {
        socket.emit(COMMAND_NEXT_ROUND)
    }

    // Decision phase
    if (gameState.reveal) {
        let phaseDetails
        let estimations = []

        let est = player.estimate
        // eslint-disable-next-line
        if (parseInt(est) == est) {
            est = parseInt(est)
        }
        if (player.status !== 'observer' && Number.isInteger(est)) {
            estimations.push(est)
        }
        players.forEach((player) => {
            if (typeof player.name === 'undefined') {
                return
            }
            est = player.estimate
            // eslint-disable-next-line
            if (parseInt(est) == est) {
                est = parseInt(est)
            }
            if (player.status !== 'observer' && Number.isInteger(est)) {
                estimations.push(est)
            }
        })

        if (gameState.nrParticipants < 2) {
            phaseDetails = <span>Waiting for enough participants</span>
        } else if (estimations.length < 1) {
            phaseDetails = <span className="has-text-danger">There are no valid estimations</span>
        } else {
            estimations = estimations.sort((a, b) => { return a > b })

            // calculate sum of estimations
            let sum = 0
            estimations.forEach((est) => { sum += est })

            // calculate average of estimations
            const avg = (sum === 0) ? 0 : (Math.round(100 * sum / estimations.length) / 100)

            phaseDetails = <span>Estimation(s): {estimations.join(", ")}. <small className="has-text-grey">Average: {avg}.</small></span>
        }

        return (
            <div className="box content">
                <p className="mb-3"><b>🔨 Decision phase</b></p>
                <p className="is-size-4 mb-4">{phaseDetails}</p>
                <div className="buttons are-small">
                    <button className="button" onClick={() => setReveal(false)}>Hide estimates again</button>
                    <button className="button is-link" onClick={() => nextRound()}>On to the next round</button>
                </div>
            </div>
        )

    } else {
        // Estimation phase
        const nrParticipants = gameState.nrParticipants
        const nrMissingVotes = nrParticipants - gameState.nrEstimates
        let phaseDetails
        let autoReveal

        if (nrParticipants < 2) {
            phaseDetails = <span className="has-text-grey">Waiting for enough participants</span>
        } else if (nrMissingVotes > 0) {
            phaseDetails = <span className="has-text-danger">Still {nrMissingVotes} of {nrParticipants} vote(s) missing</span>
        } else {
            phaseDetails = <span className="has-text-success">All {nrParticipants} vote(s) are in!</span>
        }

        if (gameState.countdown !== null) {
            autoReveal = <small className="has-text-grey">Auto-reveal in {gameState.countdown}</small>
        }

        return (
            <div className="box content">
                <p className="mb-3"><b>💭 Estimation phase</b></p>
                <p className="is-size-4 mb-4">{phaseDetails} {autoReveal}</p>
                <div className="buttons are-small">
                    <button className="button is-link" onClick={() => setReveal(true)}>Reveal estimates</button>
                    <button className="button" onClick={() => clearAllEst()}>Clear all estimates</button>
                </div>
            </div>
        )
    }
}

export default PhaseControls
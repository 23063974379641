function ParticipantCard(props) {
    const gameState = props.gameState

    const name = (props.name === null) ? <i>Unknown player</i> : props.name
    let estimate = (props.estimate === null || props.estimate === '' || props.estimate === undefined) ? null : props.estimate
    // eslint-disable-next-line
    if (parseInt(estimate) == estimate) {
        estimate = parseInt(estimate)
    }

    let estimateLabel;
    let boxClass = ["box", "has-text-centered"];
    if (props.playerStatus === "observer") {
        estimateLabel = <small>Observer</small>
        boxClass.push("has-background-light")
        boxClass.push("has-text-grey")
    } else if (gameState.reveal) {
        if (estimate === null) {
            estimateLabel = <small>Missing</small>
            boxClass.push("has-background-danger-soft")
            boxClass.push("has-text-danger-soft-invert")
        } else if (!Number.isInteger(estimate)) {
            estimateLabel = <small>{estimate}</small>
            boxClass.push("has-background-danger-soft")
            boxClass.push("has-text-danger-soft-invert")
        } else {
            estimateLabel = estimate
        }
    } else {
        if (estimate === null) {
            estimateLabel = "..."
            boxClass.push("has-background-warning-soft")
            boxClass.push("has-text-warning-soft-invert")
        } else {
            estimateLabel = <small>Ready</small>
            boxClass.push("has-background-success-soft")
            boxClass.push("has-text-success-soft-invert")
        }
    }

    return (
        <div className="column is-half-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen">
            <div className={boxClass.join(" ")}>
                <p>{name}</p>
                <h1 className={"has-text-inherit"}>{estimateLabel}</h1>
            </div>
        </div>
    );
}

export default ParticipantCard;